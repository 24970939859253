// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section__container {
  margin: 0 auto;
  max-width: 1140px;
  width: calc(1140/1280*100%);
  font-family: 'Inter';
}

@media screen and (max-width: 1023px) {
  .section__container {
    width: calc(668/768*100%);
  }
}

@media screen and (max-width: 767px) {
  .section__container {
    width: calc(284/320*100%);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Container/Container.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,2BAA2B;EAC3B,oBAAoB;AACtB;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".section__container {\n  margin: 0 auto;\n  max-width: 1140px;\n  width: calc(1140/1280*100%);\n  font-family: 'Inter';\n}\n\n@media screen and (max-width: 1023px) {\n  .section__container {\n    width: calc(668/768*100%);\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .section__container {\n    width: calc(284/320*100%);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
