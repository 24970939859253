import Main from "../Main/Main";
import Footer from "../Footer/Footer";
import "./App.css";

export default function App() {
  return (
    <>
      <Main />
      <Footer />
    </>
  );
}
