// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Inter-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Inter-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("Inter-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("Inter-Medium.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
  }
  
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
  }`, "",{"version":3,"sources":["webpack://./src/vendor/fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB;+DAC6C;EAC/C;;EAEA;IACE,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB;+DAC4C;EAC9C","sourcesContent":["@font-face {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n    src: url('Inter-Regular.woff2') format('woff2'),\n         url('Inter-Regular.woff') format('woff');\n  }\n  \n  @font-face {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 500;\n    src: url('Inter-Medium.woff2') format('woff2'),\n         url('Inter-Medium.woff') format('woff');\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
