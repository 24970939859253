const technology = [
  {
    name: "HTML",
  },
  {
    name: "CSS",
  },
  {
    name: "JS",
  },
  {
    name: "React",
  },
  {
    name: "Git",
  },
  {
    name: "Express.js",
  },
  {
    name: "mongoDB",
  },
  {
    name: "Node.js",
  },
  {
    name: "DOM",
  },
  {
    name: "WebPack",
  },
  {
    name: "React Router",
  },
  {
    name: "FlexBox",
  },
];

const anchors = [
  {
    way: "#AboutMe",
    title: "Обо мне",
  },
  {
    way: "#Portfolio",
    title: "Мои работы",
  },
  {
    way: "#Contacts",
    title: "Контакты",
  },
];

module.exports = {
  technology,
  anchors,
};
