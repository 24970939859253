// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --dark-color: #202020;
  --secondary-color: #073042;
  --main-font-color: #FFFFFF;
  --secondary-font-color: #8B8B8B;
  scroll-behavior: smooth;
}`, "",{"version":3,"sources":["webpack://./src/styles/root.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,0BAA0B;EAC1B,0BAA0B;EAC1B,+BAA+B;EAC/B,uBAAuB;AACzB","sourcesContent":[":root {\n  --dark-color: #202020;\n  --secondary-color: #073042;\n  --main-font-color: #FFFFFF;\n  --secondary-font-color: #8B8B8B;\n  scroll-behavior: smooth;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
