// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  position: absolute;
  background-color: var(--secondary-color);
  padding-bottom: 35px;
  width: 100%;
}

.header__container {
  max-width: 1140px;
  margin: 0 auto;
  width: calc(1140/1280*100%);
  background-color: var(--secondary-color);
  padding: 27.5px 0 27.5px 0;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid var(--main-font-color);
}

.header__link {
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Inter';
  color: var(--main-font-color);
  transition: opacity .3s linear;
  margin: 0 15px;
}

@media screen and (max-width: 1024px) {
  .header__container {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .header__container {
    justify-content: center;
  }
}

@media screen and (max-width: 406px) {
  .header__link {
    font-size: 15px;
    margin: 0 5px;
  }
}

@media screen and (max-width: 320px) {
  .header__container {
    align-items: center;
  }

  .header__link {
    font-size: 13px;
    margin: 0 5px;
    text-align: center;
  }
}

@media (hover: hover) {
  .header__link:hover {
    opacity: .7;
    cursor: pointer;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,wCAAwC;EACxC,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,2BAA2B;EAC3B,wCAAwC;EACxC,0BAA0B;EAC1B,aAAa;EACb,yBAAyB;EACzB,+CAA+C;AACjD;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,oBAAoB;EACpB,6BAA6B;EAC7B,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE;IACE,eAAe;IACf,aAAa;EACf;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,aAAa;IACb,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,WAAW;IACX,eAAe;EACjB;AACF","sourcesContent":[".header {\n  position: absolute;\n  background-color: var(--secondary-color);\n  padding-bottom: 35px;\n  width: 100%;\n}\n\n.header__container {\n  max-width: 1140px;\n  margin: 0 auto;\n  width: calc(1140/1280*100%);\n  background-color: var(--secondary-color);\n  padding: 27.5px 0 27.5px 0;\n  display: flex;\n  justify-content: flex-end;\n  border-bottom: 1px solid var(--main-font-color);\n}\n\n.header__link {\n  text-decoration: none;\n  text-transform: uppercase;\n  font-family: 'Inter';\n  color: var(--main-font-color);\n  transition: opacity .3s linear;\n  margin: 0 15px;\n}\n\n@media screen and (max-width: 1024px) {\n  .header__container {\n    justify-content: center;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .header__container {\n    justify-content: center;\n  }\n}\n\n@media screen and (max-width: 406px) {\n  .header__link {\n    font-size: 15px;\n    margin: 0 5px;\n  }\n}\n\n@media screen and (max-width: 320px) {\n  .header__container {\n    align-items: center;\n  }\n\n  .header__link {\n    font-size: 13px;\n    margin: 0 5px;\n    text-align: center;\n  }\n}\n\n@media (hover: hover) {\n  .header__link:hover {\n    opacity: .7;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
